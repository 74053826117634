import React, { Component } from 'react';

import LayoutBuilder from '../components/layout-builder/layout-builder';
import Header from '../components/header/header';
import PrivacyPolicySection from '../components/privacy-policy-section/privacy-policy-section';
import Security from '../images/security.jpg'

const PrivacyPolicyId = "privacy-policy-section";

export default class PrivacyPolicy extends Component<{}, {}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <LayoutBuilder>
                <Header title="Datenschutz" backgroundImage={Security} scrollToId={PrivacyPolicyId} />
                <PrivacyPolicySection id={PrivacyPolicyId}/>
            </LayoutBuilder>
        )
    }
}