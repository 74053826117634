import React from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps"
import HomeMarker from '../../images/home-marker.png';

const HOME_LAT = 51.445963;
const HOME_LNG = 8.834377;

const MapWrapper = withScriptjs(withGoogleMap((props: any) =>
    <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: HOME_LAT, lng: 8.834377 }}
        defaultOptions={{ fullscreenControl: false, zoomControl: false, streetViewControl: false, mapTypeControl: false, styles: mapStyle }} >
        {props.isMarkerShown && <Marker position={{ lat: HOME_LAT, lng: HOME_LNG }}
            icon={{ url: HomeMarker, size: { width: 51, height: 60 }, scaledSize: { width: 51, height: 60 }, }} />}
    </GoogleMap>
))

export const MapComponent = () => <MapWrapper
    isMarkerShown
    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCa9tp1ajd2sWKowVtc1SsTE18FEmPo04Y"
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ width: "100%", height: "100%" }} />}
    mapElement={<div style={{ width: "100%", height: "100%" }} />}
/>


const mapStyle = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#bed9e3"
            },
            {
                "visibility": "on"
            }
        ]
    }
]