import React, { Component } from 'react';

export default class Redirect extends Component<{ href: string }, {}> {

    render() {
        return (
            <span onClick={this.onClick} style={{ textDecorationLine: "underline", cursor:"pointer" }} >{this.props.children}</span>
        )
    }

    onClick = () => {
        const { href } = this.props;
        var win = window.open(href, '_blank');
        win && win.focus();
    }
}