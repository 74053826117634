import React, { Component } from 'react';
import './privacy-policy-section.css';
import { convertTextToHtml } from '../../utils/text-parser';

import { Section } from '../../interfaces/section-interface';
import { PrivacyPolicy } from '../../data/privacy-policy';

export default class PrivacyPolicySection extends Component<Section, {}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <div className="privacy-policy-sections-container" id={this.props.id}>
                {this.parsePrivacyPolicyObject()}
            </div>
        )
    }

    parsePrivacyPolicyObject() {
        return PrivacyPolicy.map((pp, k1) => {
            return <div className={"default-section-background privacy-policy-section-background" + (k1 % 2 == 0 ? " grey-section" : "")} key={"pp-section-" + k1}>
                <div className="default-section privacy-policy-section">
                    <h2>{pp.header}</h2>
                    {
                        pp.subsections.map((ss, k2) => {
                            return <div key={"pp-subection-" + k2}>
                                <h3>{ss.title}</h3>
                                <p>
                                    {
                                        convertTextToHtml(ss.content)
                                    }
                                </p>
                            </div>
                        })
                    }
                </div>
            </div >
        })
    }
}
