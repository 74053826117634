import React from 'react';
import Redirect from '../components/dynamic-redirect/dynamic-redirect';

export function convertTextToHtml(text: string): JSX.Element | JSX.Element[] | string {
    return getBoldText(text);
}

function getBraketText(text: string): JSX.Element[] {
    return text.split('\n').map((item, key, arr) => {
        return <span key={key}> {getLinkedText(item)} {arr.length - 1 != key && <br />}</span >
    })
}

function getBoldText(text: string): JSX.Element | JSX.Element[] | string {
    return parseTextForIndicator(text, "b", convertToBold, getBraketText)
}

function getLinkedText(text: string) {
    return parseTextForIndicator(text, "a", convertToLink)
}

function convertToBold(text: JSX.Element[] | string, convert: boolean): JSX.Element | JSX.Element[] | string {
    return converter(text, convert, "b");
}

function convertToLink(text: JSX.Element[] | string, convert: boolean, link?: string, ): JSX.Element | JSX.Element[] | string {
    const href = link ? link : text;
    return converter(text, convert, link && link.includes("redirect") ? Redirect : "a", { href: href, target: "_blank" });
}

function converter(text: string | JSX.Element[], convert: boolean, tag: React.DetailedHTMLProps<any, any>, props?: Object): JSX.Element | JSX.Element[] | string {
    return convert ? React.createElement(tag, props, text) : text;
}

function parseTextForIndicator(text: string, indicator: string,
    convertTo: (text: string | JSX.Element[], convert: boolean, option?: string) => JSX.Element | JSX.Element[] | string,
    next?: (text: string) => JSX.Element[]) {
    var regex = new RegExp("<" + indicator + ".*?>", "g");
    let matchesOpenTag = text.split(regex);
    let result = matchesOpenTag.map((item, key) => {
        let title;
        if (indicator == "a") {
            var regexTitle = new RegExp("<" + indicator + '.*?title="(.*?)".*?>', "g");
            let matches = regexTitle.exec(text);
            if (matches && matches.length > 1) {
                title = matches[1];
            }
        }
        let splittet = item.split("</" + indicator + ">");
        return <span key={key}>
            {
                convertTo(next ? next(splittet[0]) : title && splittet.length > 1 ? title : splittet[0], splittet.length > 1, title ? splittet[0] : undefined)
            }
            {
                splittet.length > 1 && next ? next(splittet[1]) : splittet[1]
            }
        </span>
    })
    return result;
}