import React, { Component } from 'react';
import './header.css';
import { MapComponent } from '../map/map';

import InteractiveIcon from '../../components/interactive-icon/interactive-icon';
import ArrowDownDouble from '../../images/arrow-down-double.png';

export interface HeaderProps {
    title?: string;
    author?: string;
    backgroundImage?: string;
    scrollToId?: string;
    showMap?: boolean;
}

export default class Header extends Component<HeaderProps, {}> {

    constructor(props: HeaderProps) {
        super(props);
    }

    render() {
        const { title, author, showMap, backgroundImage, scrollToId } = this.props;
        let HeaderInnerComponent;
        if (showMap == true) {
            HeaderInnerComponent = <div className="map-container" >
                <MapComponent />
            </div>
        } else if (title) {
            let splitetText = title.split('\\n');
            let brackedText = splitetText.map((item, key) => {
                return <span key={key}>{item}<br /></span>
            })
            HeaderInnerComponent = <div className="header-title-container" >
                <h2 data-text-before={splitetText[0]} data-text-after={splitetText.length > 1 ? splitetText[1] : ""} >{brackedText}</h2>
                {author && <h6>{author}</h6>}
            </div>
        }
        return (
            <div className="header" style={backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : undefined} >
                {
                    HeaderInnerComponent
                }
                <div className="down-arrow" >
                    <InteractiveIcon src={ArrowDownDouble} href={"#" + (scrollToId ? scrollToId : "")} alt="Arrow to scroll down"/>
                </div>
            </div>
        )
    }

}
